import { useAuth } from '~/lib/contexts/auth'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu'
import { supabase } from '~/lib/supabase'
import { toast } from 'sonner'
import { Button } from '~/components/ui/button'
import { BoxIcon, LogOutIcon, ReceiptTextIcon, UserIcon } from 'lucide-react'
import { Link } from '@remix-run/react'
import { Skeleton } from '~/components/ui/skeleton'
import { usePlan } from '~/lib/swr/plan'
import {useSWRConfig} from "swr";

export const UserMenu = () => {
  const { session, isLoaded } = useAuth()
  const { mutate } = useSWRConfig()
  const { data: plan, isLoading } = usePlan()

  if (!isLoaded) {
    return <Skeleton className={'h-10 rounded-none'} />
  }

  if (session) {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant={'ghost'} className={'justify-between focus-visible:ring-0 rounded-none'}>
            <div className={'flex items-center'}>
              <UserIcon className={'w-4 h-4 mr-2'} />
              {session.user.email}
            </div>

            {isLoading ? (
              <Skeleton className={'w-8 h-4'} />
            ) : plan === 'pro' ? (
              <div
                className={
                  'px-2 py-0.5 text-xs font-medium rounded-full bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 text-background'
                }
              >
                Pro
              </div>
            ) : (
              <div className={'px-2 py-0.5 text-xs font-medium rounded-full bg-primary text-background'}>Free</div>
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className={'w-80'}>
          <DropdownMenuLabel>My Account</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem asChild>
            <Link to={'/settings/keys'}>
              <BoxIcon className={'w-4 h-4 mr-2'} />
              Models
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Link to={'/settings/billing'}>
              <ReceiptTextIcon className={'w-4 h-4 mr-2'} />
              Billing
            </Link>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={async () => {
              const { error } = await supabase.auth.signOut()
              if (error) {
                toast.error('Something went wrong')
              } else {
                toast.error('Successfully logged out')
              }

              await mutate(() => true, undefined, false)
            }}
          >
            <LogOutIcon className={'w-4 h-4 mr-2'} />
            Logout
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    )
  } else {
    return (
      <div className={'p-4 border-t'}>
        <div className={'text-sm font-medium'}>Login to your account to enable advanced features</div>
        <Button variant={'outline'} size={'sm'} className={'mt-4 w-full'} asChild>
          <Link to={'/login'}>Login</Link>
        </Button>
      </div>
    )
  }
}
