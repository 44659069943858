import { captureRemixErrorBoundaryError } from '@sentry/remix'
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useRouteError } from '@remix-run/react'
import './tailwind.css'
import { Toaster } from '~/components/ui/sonner'
import type { ReactNode } from 'react'
import { Sidebar } from '~/components/sidebar'
import { OpenAIProvider } from '~/lib/provider/openai'
import { AuthProvider } from '~/lib/contexts/auth'
import { Sheet, SheetContent, SheetTrigger } from '~/components/ui/sheet'
import { SidebarIcon } from 'lucide-react'

export function Layout({ children }: { children: ReactNode }) {
  return (
    <html lang='en'>
      <head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <Toaster />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

export const ErrorBoundary = () => {
  const error = useRouteError()
  captureRemixErrorBoundaryError(error)
  return <div>Something went wrong</div>
}

export default function App() {
  return (
    <AuthProvider>
      <OpenAIProvider>
        <div className={'flex flex-col md:flex-row h-svh'}>
          <div className={'flex-shrink-0 border-r hidden md:flex w-80'}>
            <Sidebar />
          </div>
          <div className={'md:hidden border-b p-4'}>
            <Sheet>
              <SheetTrigger>
                <SidebarIcon className={'w-5 h-5'} />
              </SheetTrigger>
              <SheetContent side={'left'} className={'p-0'}>
                <Sidebar />
              </SheetContent>
            </Sheet>
          </div>
          <div className={'flex-grow'}>
            <Outlet />
          </div>
        </div>
      </OpenAIProvider>
    </AuthProvider>
  )
}

export function HydrateFallback() {
  return <p>Loading...</p>
}
