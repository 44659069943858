import { useAtom } from 'jotai'
import { chatsAtom } from '~/lib/stores'
import { Link, useSearchParams, useNavigate } from '@remix-run/react'
import { cn } from '~/lib/utils'
import { createId } from '@chatoo/id'
import { PlusIcon } from 'lucide-react'
import { UserMenu } from '~/components/user-menu'
import { ScrollArea } from '~/components/ui/scroll-area'

export const Sidebar = () => {
  const [chats, setChats] = useAtom(chatsAtom)

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  return (
    <div className={'flex flex-col justify-between h-full w-full'}>
      <div
        className={'flex flex-shrink-0 items-center text-xs hover:bg-gray-100 h-8 px-3 rounded cursor-pointer'}
        onClick={() => {
          const id = createId('chat')
          setChats((chats) => [...chats, { id, title: 'New chat', messages: [] }])
          navigate({ pathname: '/', search: `?chatId=${id}` })
        }}
      >
        <PlusIcon className={'w-4 h-4 mr-2'} />
        New Chat
      </div>
      <ScrollArea className={'flex-grow'}>
        {chats.toReversed().map((chat) => (
          <Link
            className={cn('flex items-center text-xs hover:bg-gray-100 h-8 px-3 rounded', {
              'bg-gray-100': searchParams.get('chatId') === chat.id,
            })}
            to={{ pathname: '/', search: `?chatId=${chat.id}` }}
            key={chat.id}
          >
            {chat.title}
          </Link>
        ))}
      </ScrollArea>

      <UserMenu />
    </div>
  )
}
